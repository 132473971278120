import React, { Component } from "react"
import { Link } from "gatsby"

import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import styled from "styled-components"
import { navigate } from "gatsby"

import { Container } from "../../global"
import {
	Nav,
	NavItem,
	Brand,
	StyledContainer,
	NavListWrapper,
	MobileMenu,
	Mobile,
	ActionsContainer,
	StyledAnchor,
	StyledLink,
} from "./style"

import Logo from "../logoImage"

const MOBILE_MENU = ["Camisetas", "Outros", "Blog", "Sobre", "Contato"]

export default class Navigation extends Component {
	state = {
		mobileMenuOpen: false,
		hasScrolled: false,
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll)
	}

	handleScroll = event => {
		const scrollTop = window.pageYOffset

		if (scrollTop > 32) {
			this.setState({ hasScrolled: true })
		} else {
			this.setState({ hasScrolled: false })
		}
	}

	toggleMobileMenu = () => {
		this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
	}

	closeMobileMenu = () => {
		if (this.state.mobileMenuOpen) {
			this.setState({ mobileMenuOpen: false })
		} else {
			if (!this.state.hasScrolled) {
				navigate("/")
			}
		}
	}

	getNavLink = item => (
		<Link to={'/'+item.toLowerCase()} onClick={this.closeMobileMenu}>
			{item}
		</Link>
	)

	getNavList = ({ mobile = false }) => (
		<NavListWrapper mobile={mobile}>
			<Scrollspy
				items={MOBILE_MENU.map(item => item.toLowerCase())}
				currentClassName="active"
				mobile={mobile}
				offset={-64}
			>
				{MOBILE_MENU.map((mobItem, index) => (
					<NavItem key={index}>{this.getNavLink(mobItem)}</NavItem>
				))}
			</Scrollspy>
		</NavListWrapper>
	)

	render() {
		const { mobileMenuOpen } = this.state

		return (
			<Nav
				{...this.props}
				scrolled={this.state.hasScrolled}
				clicked={this.state.mobileMenuOpen}
			>
				<StyledContainer>
					<Brand>
						<Scrollspy offset={-64} item={["top"]} currentClassName="active">
							<AnchorLink href="#top" onClick={this.closeMobileMenu}>
								<Logo />
							</AnchorLink>
						</Scrollspy>
					</Brand>
					<Mobile>
						<button
							onClick={this.toggleMobileMenu}
							style={{ color: "black", background: "none" }}
						>
							{this.state.mobileMenuOpen ? (
								<X size={24} alt="close menu" />
							) : (
								<Menu size={24} alt="open menu" />
							)}
						</button>
					</Mobile>

					<ActionsContainer>
					    <StyledMenuLink to={'/camisetas'}>Catálogo</StyledMenuLink>
					    <StyledMenuLink to={'/orcamento'}>Orçamento</StyledMenuLink>
					</ActionsContainer>
				</StyledContainer>
				<Mobile>
					{mobileMenuOpen && (
						<MobileMenu>
							<Container>{this.getNavList({ mobile: true })}</Container>
						</MobileMenu>
					)}
				</Mobile>
			</Nav>
		)
	}
}

const StyledMenuLink = styled(StyledLink)`
	font-family: ${props => props.theme.font.normal};
	${props => props.theme.font_size.xsmall};
	color: white;
	background: ${props => props.theme.color.secondary};
	border-radius: 4px;
	padding: 8px 12px;
	text-transform: uppercase;
	font-size: 10px;

	letter-spacing: inherit;
	font-weight: inherit;
	height: auto;

	margin-right: 12px;

	min-width: 90px;
`
