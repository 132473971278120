import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  description,
  pathname,
  image: metaImage,
  keywords,
  article,
  date,
  lang,
  meta,
}) => {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            headline
            author
            siteUrl
            keywords
            buildTime
          }
        }
        file(sourceInstanceName: { eq: "geral" }, name: { eq: "og-image" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
      }
    `
  )

  const defaultImage = metaImage ? metaImage : file.childImageSharp.resize
  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords
  const image =
    defaultImage && defaultImage.src
      ? `${site.siteMetadata.siteUrl}${defaultImage.src}`
      : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: site.siteMetadata.siteUrl,
    headline: site.siteMetadata.headline,
    inLanguage: lang,
    description: site.siteMetadata.description,
    name: site.siteMetadata.title,
    author: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    publisher: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    datePublished: "2020-05-27",
    dateModified: site.siteMetadata.buildTime,
    image: {
      "@type": "ImageObject",
      url: `${site.siteMetadata.siteUrl}${file.childImageSharp.resize.src}`,
    },
  }

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": site.siteMetadata.siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      publisher: {
        "@type": "Organization",
        name: site.siteMetadata.author,
        logo: {
          "@type": "ImageObject",
          url: `${site.siteMetadata.siteUrl}${file.childImageSharp.resize.src}`,
        },
      },
      datePublished: date,
      dateModified: date,
      description: metaDescription,
      headline: metaTitle,
      inLanguage: lang,
      url: canonical,
      name: metaTitle,
      image: {
        "@type": "ImageObject",
        url: image,
      },
      mainEntityOfPage: canonical,
    }

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": "https://www.ouroestamparia.com.br/blog/",
        name: "Blog",
      },
      position: 2,
    })

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": canonical,
        name: metaTitle,
      },
      position: 3,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `facebook-domain-verification`,
          content: `4xy2x0jy235bn79bc6rv3qio9vczak`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords.join(","),
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          defaultImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: defaultImage.width,
                },
                {
                  property: "og:image:height",
                  content: defaultImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    >
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  description: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
