import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Serviços</span>
        <ul>
          <li>
            <Link to="/camisetas">Camisetas</Link>
          </li>
          <li>
            <Link to="/outros">Outros</Link>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Recursos</span>
        <ul>
          <li>
            <a
              href="https://www.ouroboros.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nossa Marca
            </a>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Empresa</span>
        <ul>
          <li>
            <Link to="/sobre">Sobre</Link>
          </li>
          <li>
            <Link to="/contato">Contato</Link>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Social</span>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/ouroestamparia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ouroestamparia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;

      a {
        text-decoration: none;
        color: ${props => props.theme.color.primary};
      }

      a:hover {
        color: ${props => props.theme.color.accent};
      }
    }
  }
`

export default Footer
