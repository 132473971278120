import styled from "styled-components"

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`

export const Section = styled.section`
  padding: 80px 0;
  overflow: hidden;
  background-color: ${props => {
    switch (props.accent) {
      case "secondary":
        return props.theme.color.white.dark
      case "main":
        return props.theme.color.primary
      default:
        return "white"
    }
  }};

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 80px 0;
  }

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === "secondary"
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`

export const ButtonAnchor = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.white.regular};
  letter-spacing: 1px;
  height: 60px;
  display: block;

  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.accent};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.55) 0px 2px 10px 0px;
  }
  &:disabled,
  &[disabled]{
    color: ${props => props.theme.color.black.lightest};
    background: ${props => props.theme.color.background.light};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Anchor = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.white.regular};
  letter-spacing: 1px;
  height: 60px;
  display: block;

  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.accent};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.55) 0px 2px 10px 0px;
  }
  &:disabled,
  &[disabled]{
    color: ${props => props.theme.color.black.lightest};
    background: ${props => props.theme.color.background.light};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
